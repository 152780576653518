import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BrandListPage from '../components/brand-list-page';

const AllBrandsTemplate = (
  { data, pageContext },
) => <BrandListPage {...data} pageContext={pageContext} />;

AllBrandsTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AllBrandsTemplate;

export const query = graphql`
query AllBrandsQuery($url: String, $lang: String) {
  datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
    ...SeoMetaTags
    ...header
    ...content
    metaCanonical
    metaRobots
    textcolorHeader
    textcolorHeaderMobile
  }
  
  brandsByCollection: allDatoCmsBrand(
    filter: {
      locale: {eq: $lang}
    }
  ) {
    nodes {
      id
      name
      fullUrlPath
      isExternal
      teaser { ...genericTeaserImage }
    }
  }

  filterQueryNumber: allDatoCmsBrand(
    filter: {
      locale: {eq: "de"} 
      name: {regex: "/^[0-9]/"}
    }
  ) {
    nodes {
      id
      name
      fullUrlPath
      isExternal
      teaser { ...genericTeaserImage }
    }
  }
}
`;
